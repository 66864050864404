<script setup lang="ts">
// catch all for DatoCMS Page model
import { type SeoMetaTagType, toHead } from "vue-datocms";

definePageMeta({ middleware: "block-extensions" });

const { t } = useI18n();
const { params } = useRoute();

const { data } = await useFetch("/api/cms/page", {
  query: { slug: params.slug || "home" },
});

if (!data.value) {
  throw create404Error();
}

useDatoSlugsToI18n(data.value._allSlugLocales!);

useHead(() => {
  if (!data.value) {
    return {};
  }

  return toHead(data.value._seoMetaTags as Array<SeoMetaTagType>);
});

useSwipers();
</script>

<template>
  <div v-if="data">
    <PageTitle v-if="data.title !== t('page.title.home')" :data="data.title" class="my-4" />
    <SectionsRenderer :sections="data.sections" :current-page-id="data.id" />
  </div>
</template>

<style scoped>

</style>
